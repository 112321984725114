$(function () {
    setHeaderScroll();
    hamburger();
});


//Lightcase
$(document).ready(function() {
	$('a[data-rel^=lightcase]').lightcase();

  	//Sub-menu
	var button_text = "Zobrazit navigaci";
	$('#page-detail__menu-mobile-trigger').on('click', function (e) {
		e.preventDefault();
		$(this).text(
			button_text == "Skrýt navigaci" ? "Zobrazit navigaci" : "Skrýt navigaci"
		);
		$('#page-detail__submenu').slideToggle(200);
		button_text = $(this).text();
	});

  $('.services__wrapper').slick({
    centerMode: true,
    slidesToShow: 3,
    prevArrow: $('.slick__arrow.arrow__prev.banner__arrow'),
		nextArrow: $('.slick__arrow.arrow__next.banner__arrow'),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      }
    ]
	});

  // Show/hide answer
	$('.showRegions').click(function(e) {
		e.preventDefault();
		$(this).parent('.price-list__regions-wrapper').find('.price-list__dropdown').slideToggle(200);
		$(this).toggleClass('--shown');
	});
});
